.header {
    position: sticky;
    top: 0px;
    z-index: 99;
    background-color: #fff;
    height: var(--nav-height);
    display: flex;
  }
  .my-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.3rem;
    /* padding: 24px 0px; */
  }
  .nav-link_container {
    display: flex;
    align-items: center;
    text-align: center;
    gap: 42px;
    margin: 0;
  }

  .nav-link_container a {
    text-decoration: none;
    color: #2C334D;
    font-size: 1.2rem;
    font-family: "Protest Revolution", sans-serif;
    transition: text-decoration 300ms;
  }

  .nav-link_container a::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #2C334D;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }
  
  .nav-link_container a:hover::before {
    transform: scaleX(1);
  }



  .nav-overlay {
    /* display: none; */
    min-height: calc(100vh - var(--nav-height));
    width: 100%;
    position: absolute;
    top: var(--nav-height);
    left: 0;
    background: black;
    opacity: 0.2;
  
    z-index: 99;
    display: none;
  }
  .brand-logo,
  .my-nav-link,
  .my-nav-link span {
    font-weight: 600;
    cursor: pointer;
    position: relative;
  }

  .brand-logo { 
    height: var(--nav-height);
    margin: 0 20px;
  }
  
  .cart-icon {
    position: relative;
    display: block;
    cursor: pointer;
  }
  .cart-counter {
    position: absolute;
    left: 100%;
    border-radius: 50%;
    top: 0%;
    transform: translate(-75%, -25%);
    font-size: 10px;
    height: 14px;
    width: 14px;
    text-align: center;
    background-color: black;
    color: white;
  
    /* border: 1px solid black; */
  }
  .visible .cart-slide_overlay {
    background-color: #000;
    opacity: 0.2;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 999;
  }
  .nav-secondary_btn {
    position: absolute;
    display: flex;
    gap: 4px;
    flex-direction: column;
    display: none;
  }
  .nav-secondary_btn span {
    height: 2px;
    width: 20px;
    background-color: #2C334D;
    display: block;
    border-radius: 6px;
    transition: transform 0.2s;
  }
  .nav-open .nav-secondary_btn span:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }
  .nav-open .nav-secondary_btn span:nth-child(2) {
    width: 0px;
  }
  
  .nav-open .nav-secondary_btn span:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
  }
  
  @media (max-width: 1050px) {
    .nav-link_container {
      gap: 36px;
    }
  }
  @media (max-width: 1000px) {
    .header {
      border-bottom: 1px solid #999;
    }
    .brand-logo {
      left: 50%;
      transform: translateX(-50%);
      margin: 0;
    }
    .nav-link_container {
      align-items: normal;
      text-align: left;
      z-index: 999;
      position: absolute;
      top: var(--nav-height);
      flex-direction: column;
      left: 0;
      transform: translateX(-150%);
      transition: transform 0.3s;
      gap: 0px;
      min-height: calc(100vh - var(--nav-height));
      background-color: #fff;
      .my-nav-link {
        padding: 0px 40px;
        border-bottom: 1px solid var(--primary-background);
      }
      .my-nav-link a {
        padding: 20px 0px;
        display: block;
      }

      padding: 0;

    list-style-type: none;
    }
    .nav-open .nav-link_container {
      transform: translateX(0%);
    }
  
    .my-nav {
      width: 100% !important;
      margin: 0 !important;
      padding: 0px 30px;
    }
    .nav-secondary_btn {
      display: flex;
      cursor: pointer;
    }
    .nav-open .nav-overlay {
      display: block;
    }
  }
  