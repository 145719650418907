.contacts-font {
    color: #2C334D;
}

a {
    font-size:14px;
    font-weight:700
  }
  .superNav {
    font-size:13px;
  }
  .form-control {
    outline:none !important;
    box-shadow: none !important;
  }
  @media screen and (max-width:540px){
    .centerOnMobile {
      text-align:center
    }
  }